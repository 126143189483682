// import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        <p>
          {/* Edit <code>src/App.js</code> and save to reload. */}
          Finally, you reached, you know I was waiting only for you.
          <hr/>
        </p>
        {/* <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        > */}
          <p>SATURN Is Rising . . .</p>
          {/* <div>
            <p> 
              This is about your captain T014, you know what happened to him!
            </p>
            <p>Your war and his blood...</p> 
            <p>He still fighting for you</p>
            <p>But he is weak now, only three are on the battlefield now to support him.</p>
            <p> *** is on its way.</p>
          </div> */}
          <div>
            <p>I will meet you Soon.</p>
          </div>

        {/* </a> */}
      </header>
    </div>
  );
}

export default App;
